<template>
  <div class="main_style">
    <div class="header">
      <div class="header-text">
        <p class="header-title">众享数据共享平台</p>
        <p class="header-desc">
          方案以联盟链技术为支撑，通过数据加密和签名技术，使双方实现数据的精准交换和共享，平台包含数据上传、数据合约、数据共享和数据查询等主要功能。
        </p>
      </div>
    </div>
    <!-- 产品介绍 -->
    <div class="intro">
      <div class="commom-title">方案介绍</div>
      <div class="intro-content">
        <p>
          利用联盟链技术构建数据共享平台，在数据共享的各方部署可信节点，连接数据使用方、数据提供方、建模服务方、监管方等，共同组成可信的区块链网络。将数据收集、存储、管理、共享、开放、分析、结算过程上链存证，实现数据全生命周期可信管控，形成数据使用事前审核、事中监控、事后溯源的数据监管机制。<br/><br/>方案支持多种数据交换模式，可以支持加密密文数据交换和共享，支持基于数据目录、数据授权的共享，也支持基于数据模型、数据计算的数据共享。<br/><br/>方案旨在解决各方之间信任壁垒问题，细化数据确权机制，精准实现身份数据、数据目录、数据定义、数据需求、共享数据的链上操作，保障数据的所有权、使用权、收益权不受侵犯。
        </p>
      </div>
    </div>
    <!-- 产品架构 -->
    <div class="framework">
      <div class="commom-title">方案架构</div>
      <div class="framework-content">
        <img src="https://image.peerfintech.cn/peerfintech_website_v2/solution/share/framework.png" />
      </div>
    </div>
    <!-- 产品优势 -->
    <div class="advantage">
      <div class="commom-title">客户价值</div>
      <div class="advantage-content">
        <el-row :gutter="24">
          <el-col :span="12"
            ><div class="grid-content">
              <div class="img">
                <img src="@/assets/img/solution/share/role-1.png" />
              </div>
              <div class="title">政府部门</div>
              <div class="text">
                政务数据的共享能够更大的发挥政府部门职能，并能够打造真正意义上的数字政府、数字城市，进而推动整个社会的数字化转型进程。
              </div>
            </div></el-col
          >
          <el-col :span="12"
            ><div class="grid-content">
              <div class="img">
                <img src="@/assets/img/solution/share/role-2.png" />
              </div>
              <div class="title">数据交易平台</div>
              <div class="text">
                实现数据在共享及流通过程中对数据确权，明确数据的产生者、使用者、管理者及受益者，实现数据的精准授权，促进数据的共享及流通。
              </div>
            </div></el-col
          >
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12"
            ><div class="grid-content">
              <div class="img">
                <img src="@/assets/img/solution/share/role-3.png" />
              </div>
              <div class="title">数据提供方</div>
              <div class="text">
                应用零知识证明技术，可以在密文情况下实现数据的关联关系验证，在保障数据隐私的同时实现数据共享。
              </div>
            </div></el-col
          >
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    // 页面回退顶部
    window.scrollTo(0, 0);
  },
};
</script>
<style lang="scss" scoped>
.main_style {
  background-color: #ffffff;
  .content-width {
   width: 1200px;
    margin: 0px auto;
  }
  .commom-title {
    padding: 70px 0px 50px;
    font-size: 32px;
    font-family: "PingFangSC-Medium";
    color: rgba(0, 0, 0, 0.85);
    line-height: 45px;
    text-align: center;
  }
  .header {
    width: 100%;
    height: 540px;
    background: url("https://image.peerfintech.cn/peerfintech_website_v2/solution/share/bg.png")
      center/cover no-repeat;
    .header-text {
      @extend .content-width;
      .header-title {
        font-size: 36px;
        font-family: "PingFangSC-Medium";
        color: #000000;
        line-height: 50px;
        padding-top: 160px;
      }
      .header-desc {
        width: 510px;
        color: rgba(0,0,0,0.75);
        font-size: 20px;
        line-height: 33px;
        margin-top: 36px;
      }
    }
  }
  .intro {
    .intro-content {
      @extend .content-width;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.65);
      line-height: 25px;
    }
  }
 
  .framework {
    padding-bottom: 70px;
    .framework-content {
      @extend .content-width;
      img {
        width: 100%;
      }
    }
  }
  .advantage {
    background: #f4f7fc;
    .advantage-content {
      @extend .content-width;
      padding-bottom: 70px;
      .el-row {
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .grid-content {
        height: 370px;
        background: #ffffff;
        box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.1);
        padding: 40px 48px 0px 40px;
        .img {
          width: 124px;
          height: 124px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .title {
          padding: 24px 0px;
          font-size: 22px;
          font-family: "PingFangSC-Medium";
          color: rgba(0, 0, 0, 0.85);
          line-height: 30px;
        }
        .text {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.45);
          line-height: 22px;
        }
      }
    }
  }
}
</style>
